import * as React from "react"
import Header from "./Header"
import Footer from "./Footer"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"
import { motion } from "framer-motion"
import CookieConsent from "react-cookie-consent"
import { Link, Trans } from "gatsby-plugin-react-i18next"

const Layout: React.FC = ({ children }) => {
	return (
		<>
			<GlobalStyles />
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, delay: 0.25 }}
			>
				<Typography />
				<Header />
				<main className="main-body">{children}</main>
				<Footer />
				<CookieConsent
					location="bottom"
					buttonText="Okay"
					cookieName="bonnevilleCookie"
					expires={150}
					style={{
						background: "var(--black)",
						padding: "var(--spacing)",
						fontSize: "16px",
						boxSizing: "border-box",
					}}
					buttonStyle={{
						padding: "1rem",
						color: "var(--black)",
						backgroundColor: "#fff",
						fontSize: "16px",
					}}
				>
					<Trans>This website uses cookies to help improve your experience. By using this site you agree to it.</Trans>
					{" "}
					<Link to="/data-protection" style={{ color: "#fff" }}>
						<Trans>Data Protection</Trans>
					</Link>
					.
				</CookieConsent>
			</motion.div>
		</>
	)
}

export default Layout

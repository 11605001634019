import * as React from "react"
import { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-react-i18next"
import Burger from "./Burger"
import Navigation from "./Navigation"
import { HeaderStyles } from "../styles/NavStyles"
import { GatsbyImage } from "gatsby-plugin-image"

const Header: React.FC = () => {
	const data = useStaticQuery(
		graphql`
			query {
				logo: file(relativePath: {eq: "logo.png"}) {
					childImageSharp {
						gatsbyImageData(
							layout: CONSTRAINED
							height: 60
							width: 150
							transformOptions: {fit: CONTAIN}
						)
					}
				}
				site {
					siteMetadata {
						title
					}
				}
			}
		`,
	)

	const [scroll, setScroll] = useState(false)

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50)
		})
	}, [])

	return (
		<HeaderStyles className={scroll ? "scrolled" : null}>
			<div className="logo-cont">
				<Link to="/">
					<GatsbyImage
						image={data.logo.childImageSharp.gatsbyImageData}
						alt={data.site.siteMetadata.title}
					/>
				</Link>
			</div>
			<Burger />
			<Navigation />
		</HeaderStyles>
	)
}

export default Header

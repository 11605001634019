import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby-plugin-react-i18next"
import { mainMenuItems } from "../constants/menu-items"
import { NavigationStyles } from "../styles/NavStyles"
import MenuContext from "./MenuContext"
import { useTranslation } from "react-i18next"

const Navigation: React.FC = () => {
	const [isOpen, setNav] = useContext(MenuContext)
	const { t } = useTranslation()

	const toggleNav = () => {
		setNav(isOpen => !isOpen)
	}

	return (
		<NavigationStyles className={isOpen ? "open" : "closed"}>
			<ul>
				{mainMenuItems.map((item, index) => (
					<li key={`menu-item-${index}`} className="nav-link">
						<Link to={item.path} onClick={toggleNav}>
							{t(item.title)}
						</Link>
					</li>
				))}
			</ul>
		</NavigationStyles>
	)
}

export default Navigation

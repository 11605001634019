import * as React from "react"
import { DiGithubBadge } from "react-icons/di"
import { FaLinkedin, FaTwitter } from "react-icons/fa"

export const mainMenuItems = [
	{
		path: "/",
		title: "Home",
	},
	{
		path: "/posts",
		title: "Posts",
	},
	{
		path: "/contact",
		title: "Contact",
	},
]

export const socialMenuItems = [
	//{
	//	icon: <FaLinkedin />,
	//	url: "https://www.linkedin.com/in/morgan-baker-developer-inverness",
	//	name: "LinkedIn",
	//},
	{
		icon: <FaTwitter />,
		url: "https://www.twitter.com/OdaniaIT",
		name: "Twitter",
	},
	{
		icon: <DiGithubBadge />,
		url: "https://github.com/Odania-IT",
		name: "GitHub",
	},
]

export const footerMenuItems = [
	{
		path: "https://www.odania.com/pages/data-protection",
		title: "Data Protection",
	},
	{
		path: "/https://www.odania.com/pages/imprint",
		title: "Imprint",
	},
]
